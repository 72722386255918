import { useEffect, useRef, useState } from "react";
import { onValue } from "firebase/database";

import { monumentZonesRef } from "../Services/FirebaseDB";
import ZonesByTypeZone from "../Types/ZonesByTypeZone";

export const useMonumentZones = () => {
  const monumentZones = useRef<Record<number, ZonesByTypeZone>>({});

  useEffect(() => {
    const fetchMonumentZones = () => {
      onValue(monumentZonesRef, (snapshot) => {
        const monumenZonesData = snapshot.val();
        monumentZones.current = monumenZonesData;
      });
    };

    fetchMonumentZones();
  }, []);

  return monumentZones;
};
