import firebaseApp from "./Firebase";

import { getDatabase, ref } from "firebase/database";

const db = getDatabase(firebaseApp);

const dailyRef = ref(db, "daily");
const medalsRef = ref(db, "medals");
const medalsFeedRef = ref(db, "feeds/medals");
const medalsRareFeedRef = ref(db, "feeds/medalsRare");
const monumentZonesRef = ref(db, "zonesByType/Monument");
const supportersRef = ref(db, "supporters");
const turfersRef = ref(db, "turfers");

export {
  dailyRef,
  db,
  medalsFeedRef,
  medalsRef,
  medalsRareFeedRef,
  monumentZonesRef,
  supportersRef,
  turfersRef,
};
